import { ISendSignature, Info, SendSignatureDTO } from './../core/interfaces/digital-signature-send';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalSignatureController } from '../core/controllers/digital-signature.controller';
import { environment } from 'src/environments/environment';
import { PrefixState } from '../core/classes/prefix-state';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent implements OnInit {
  signatureForm: FormGroup;

  PrefixJsonPath: string = "/assets/json/prefix.json";

  yousign: any;
  showFrame = false;
  iFrame = false;
  finish = false;
  token: string = '';
  fileUrl: string = "";
  tokenValid: boolean | null = true;  // Stato del token


  countryPrefixes : PrefixState[] | undefined = [];

    constructor(
      private fb: FormBuilder, 
      private route: Router, 
      private activatedRoute: ActivatedRoute, 
      private digitalSignatureController: DigitalSignatureController,
      private http: HttpClient
    ) {
    this.signatureForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      prefix: ['+39', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });


  }

  async ngOnInit(): Promise<void> {
    
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params['token']) {
        this.token = params['token'];
        this.fileUrl = `${environment.projectConfiguration.API_URL}/DocumentSign/GetFileByToken?token=${this.token}`
      }
    });

    this.countryPrefixes = await this.http.get<PrefixState[]>(this.PrefixJsonPath).toPromise();

  }

  async ngAfterViewInit(): Promise<void> {

    this.tokenValid = await this.checkToken();

  }

  viewContract() {
    // Inserire link
    window.open('/', '_blank');
  }

  onSubmit() {

    const sendSignatureDTO = this.getSendSignatureDTO();
    if (sendSignatureDTO == null) return;

    this.digitalSignatureController.activateSignature(sendSignatureDTO).then((data) => {
      if(data?.signers?.length == undefined || data?.signers?.length == null || data?.signers?.length <= 0){
        this.getErrorEvent();
        return;
      }

      if(data?.signers[0] === undefined || data?.signers[0] === null) {
        this.getErrorEvent();
        return;
      }
        
      this.showFrame = true;

      

      setTimeout(() => {
        this.yousign = new Yousign({
          signatureLink: data?.signers[0].signature_link,
          iframeContainerId: 'iframe-container',
          isSandbox: environment.production == false, //To set if you use the sandbox environment
        });
      
        this.yousign.onSignatureDone((data: any) => {
          setTimeout(() => {
            this.finish = true;
            this.route.navigate(['/digital-signature/thank-you']);
            if (this.iFrame) {
              this.showFrame = false;
            }
          }, 15000);
        });
  
        
        const THIS = this;

        window.addEventListener('message', function(e) {
          // Check if the origin of the message is really come from Yousign
          if(e.origin === 'https://yousign.app' && e.data.type === 'yousign'){
            if(e.data.event === 'declined'){ THIS.getErrorEvent(); }
            if(e.data.event === 'error'){ THIS.getErrorEvent(); }
          }
        });

      }, 1000);



      this.iFrame = true;
    }).catch((error) => {
      console.log(error);
    });

  
  }

  disableIframe() {

    if (this.finish)
      this.route.navigate(['/digital-signature/thank-you']);

    if (this.iFrame) {
      this.showFrame = false;
    }
  }

  private getSendSignatureDTO(): SendSignatureDTO | null {
    if (!this.signatureForm.valid) return null;

    const phone_number = this.signatureForm.get('prefix')?.value + this.signatureForm.get('phone')?.value;

    const sendSignature: ISendSignature = {
      SignerInfo: new Info({
        first_name: this.signatureForm.get('name')?.value,
        last_name: this.signatureForm.get('surname')?.value,
        phone_number : phone_number,
      }),
      Token: this.token
    }

    return new SendSignatureDTO().create(sendSignature);
  }

  private getErrorEvent(message: string = '') {
    setTimeout(() => {
      window.location.href = "/digital-signature/error-message";
    }, 1000);
  }

  async checkToken(): Promise<boolean> {
    
    if (!this.token) return false;

    const sendSignature: ISendSignature = {
      SignerInfo: new Info({
        first_name: '',
        last_name: '',
        phone_number : '',
      }),
      Token: this.token
    }

    return await this.digitalSignatureController.checkToken(sendSignature);
  }
}
